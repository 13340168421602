'use strict';

angular.module('performance-evaluation', ['angular-echarts', 'paginationModule'])

/**
 * ltl : Lead To Lead
 * ltc : Lead To Collaborator
 */
.config([ '$stateProvider', function($stateProvider) {
    $stateProvider.state('performance-evaluation', {
        url: '/performance-evaluation',
        templateUrl: 'components/performance_evaluation/performance-evaluation.html',
        controller: 'PerformanceEvaluationsController'
    })

    .state('my-evaluation', {
        url: '/my-evaluation',
        templateUrl: 'components/performance_evaluation/my-evaluation.html',
        controller: 'MyEvaluationController'
    })

    .state('confirm-performance-evaluation', { 
	    url: '/evaluation/step-4/confirm',
	    templateUrl: 'components/performance_evaluation/confirm-performance-evaluation.html',
        controller: 'ConfirmPerformanceEvaluationCtrl',
        resolve: {}
    })

    .state('search-evaluation', {
        url: '/search-evaluation',
        templateUrl: 'components/performance_evaluation/search-evaluation.html',
        controller: 'SearchEvaluationController'
    })

    .state('ltl-first-step', {
        url: '/performance-evaluation/ltl/step-1/',
        templateUrl: 'components/performance_evaluation/lead_to_lead/first-step.html',
        controller: 'PerformanceEvaluationLtlFirstStepController',
        params: {
            evaluationId: undefined
        }
    })

    .state('ltl-first-step-c', {
        url: '/performance-evaluation/ltl/step-1-c/',
        templateUrl: 'components/performance_evaluation/lead_to_lead/first-step-c.html',
        controller: 'PerformanceEvaluationLtlFirstStep1Controller',
        params: {
            evaluationId: undefined
        }
    })

    .state('ltl-second-step', {
        url: '/performance-evaluation/ltl/step-2/',
        templateUrl: 'components/performance_evaluation/lead_to_lead/second-step.html',
        controller: 'PerformanceEvaluationLtlSecondStepController',
        params: {
            evaluationId: undefined
        }
    })
    .state('ltl-third-step', {
        url: '/performance-evaluation/ltl/step-3/',
        templateUrl: 'components/performance_evaluation/lead_to_lead/third-step.html',
        controller: 'PerformanceEvaluationLtlThirdStepController',
        params: {
            evaluationId: undefined
        }
    })
    .state('ltl-fourth-step', {
        url: '/performance-evaluation/ltl/step-4/',
        templateUrl: 'components/performance_evaluation/lead_to_lead/fourth-step.html',
        controller: 'PerformanceEvaluationLtlFourthStepController',
        params: {
            evaluationId: undefined,
            disabled: false,
            adminWatching: false
        }
    })
    .state('ltl-fifth-step', {
        url: '/performance-evaluation/ltl/step-5/',
        templateUrl: 'components/performance_evaluation/lead_to_lead/fifth-step.html',
        controller: 'PerformanceEvaluationLtlFifthStepController',
        params: {
            evaluationId: undefined,
            disabled: false,
            adminWatching: false
        }
    })
    .state('ltl-fifth-step-summary', {
        url: '/performance-evaluation/ltl/step-5-summary/',
        templateUrl: 'components/performance_evaluation/lead_to_lead/fifth-step-summary.html',
        controller: 'PerformanceEvaluationLtlFifthStepSummaryController',
        params: {
            evaluationId: undefined,
            disabled: false,
            adminWatching: false
        }
    })
    .state('ltc-first-step', {
        url: '/performance-evaluation/ltc/step-1/',
        templateUrl: 'components/performance_evaluation/lead_to_collaborator/first-step.html',
        controller: 'PerformanceEvaluationLtcFirstStepController',
        params: {
            evaluationId: undefined
        }
    })
    .state('ltc-second-step', {
        url: '/performance-evaluation/ltc/step-2/',
        templateUrl: 'components/performance_evaluation/lead_to_collaborator/second-step.html',
        controller: 'PerformanceEvaluationLtcSecondStepController',
        params: {
            evaluationId: undefined
        }
    })
    .state('ltc-third-step', {
        url: '/performance-evaluation/ltc/step-3/',
        templateUrl: 'components/performance_evaluation/lead_to_collaborator/third-step.html',
        controller: 'PerformanceEvaluationLtcThirdStepController',
        params: {
            evaluationId: undefined,
            disabled: false,
            adminWatching: false
        }
    })

    .state('exclude-evaluation', {
        url: '/exclude-evaluation',
        templateUrl: 'components/performance_evaluation/exclusions/exclude-evaluation.html',
        controller: 'ExcludeEvaluationCtrl',
        resolve: {}
    });

}])

.service('PerformanceEvaluationService', [
    '$http',
    'restApiUrl',
    function($http, restApiUrl) {

    var service = {};
    service.GetPendingPerformanceEvaluations = GetPendingPerformanceEvaluations;
    service.GetPerformanceEvaluation = GetPerformanceEvaluation;
    service.PostPerformanceEvaluation = PostPerformanceEvaluation;
    service.SaveEvaluationProgress = SaveEvaluationProgress;
    service.UpdateStatePerformanceEvaluation = UpdateStatePerformanceEvaluation;
    service.GetPerformanceEvaluationMe = GetPerformanceEvaluationMe;
    service.GetEvaluations = GetEvaluations;
    service.GetEvaluationsFinished = GetEvaluationsFinished;
    service.GetSubEvaluations = GetSubEvaluations;
    service.EnableEvaluation = EnableEvaluation;
    service.DocumentPrint = DocumentPrint;
    service.GetEvaluationStatistics = GetEvaluationStatistics;
    service.GetEvaluationStrategicDriverValues = GetEvaluationStrategicDriverValues;
    service.GetEvaluationBehaviorValues = GetEvaluationBehaviorValues;
    
    // return $http.get(`${restApiUrl}/users/me`);

    service.ExcludePerformanceEvaluation = ExcludePerformanceEvaluation;
    service.IncludePerformanceEvaluation = IncludePerformanceEvaluation;
    service.GetExcludedPerformanceEvaluations = GetExcludedPerformanceEvaluations;

    function GetEvaluationStatistics () {
        return $http.get(`${restApiUrl}/performance-evaluations/statistics`)
    }
    function GetPendingPerformanceEvaluations () {
        return $http.get(`${restApiUrl}/performance-evaluations/pending`)
    }
    function ExcludePerformanceEvaluation (periodExclusionDto) {
        return $http.post(`${restApiUrl}/performance-evaluations/exclude`, periodExclusionDto)
    }
    function IncludePerformanceEvaluation (evaluationId) {
        return $http.post(`${restApiUrl}/performance-evaluations/include/`, evaluationId)
    }
    function GetExcludedPerformanceEvaluations () {
        return $http.get(`${restApiUrl}/performance-evaluations/excluded`)
    }
    function GetPerformanceEvaluation (id) {
        return $http.get(`${restApiUrl}/performance-evaluations/${id}`)
    }
    function PostPerformanceEvaluation (evaluation) {
        return $http.post(`${restApiUrl}/performance-evaluations/save`, evaluation);
    }
    function SaveEvaluationProgress (evaluation) {
        return $http.post(`${restApiUrl}/performance-evaluations/save/progress`, evaluation);
    }
    function UpdateStatePerformanceEvaluation (evaluation) {
        return $http.post(`${restApiUrl}/performance-evaluations/update/state`, evaluation);
    }
    function GetPerformanceEvaluationMe () {
        return $http.get(`${restApiUrl}/performance-evaluations/me`)
    }

    function GetEvaluations (params) {
        // let parsedParams = Object.keys(params).reduce((xs, x) => `${x}=${params[x]}&${xs}`, ''); 
        let uri = `${restApiUrl}/performance-evaluations`;
        
        // let uri = `${restApiUrl}/users`;
        console.log(uri, params);
        return $http.get(uri, { params: params });
        //   }
        // return $http.get(uri);
    }
    function GetEvaluationsFinished (params) {
        let uri = `${restApiUrl}/performance-evaluations/finished`;
        
        console.log(uri, params);
        return $http.get(uri, { params: params });

    }
    function GetSubEvaluations (params) {
        let parsedParams = Object.keys(params).reduce((xs, x) => `${x}=${params[x]}&${xs}`, ''); 
        let uri = `${restApiUrl}/performance-evaluations/subevaluations?${parsedParams}`;
        return $http.get(uri);
    }

    function EnableEvaluation (id) {
        return $http.post(`${restApiUrl}/performance-evaluations/enable/${id}`);
    }
    function DocumentPrint (evaluationId) {
        return $http.get(`${restApiUrl}/performance-evaluations/print/${evaluationId}`, { responseType: 'arraybuffer' });
    }

    function GetEvaluationStrategicDriverValues() {
        return $http.get(`${restApiUrl}/performance-evaluations/enums/evaluation-strategic-driver`);
    }

    function GetEvaluationBehaviorValues() {
        return $http.get(`${restApiUrl}/performance-evaluations/enums/evaluation-behavior`);
    }
    
    return service;
    }

])

.controller('PerformanceEvaluationsController', ['$scope', 'PerformanceEvaluationService', 'PeriodService', 'UsersService', '$rootScope', '$state', '$localStorage', '$window', '$uibModal', function($scope, PerformanceEvaluationService, PeriodService,  UsersService, $rootScope, $state, $localStorage, $window, $uibModal) {
    $scope.loggedUsername = $scope.loggedUser.email.toLowerCase();

    // Collapse variables
    $scope.collapsePending = false;
    $scope.collapseFilter = true;
    $scope.collapseGrilla = false;
    // ----------------
    $scope.selected_collaborator = null;
    $scope.selected_period = null;
    $scope.selected_general_performance = null;
    
    $scope.selected_collaborator_2 = null;
    $scope.selected_period_2 = null;
    $scope.selected_general_performance_2 = null;
    $scope.periods = null;

    $scope.generalPerformances = [
        'Supera expectativas',
        'Alcanza expectativas',
        'Alcanza parcialmente expectativas',
        'No alcanza expectativas',
    ];

    $scope.states = [
        'PENDING',
        'IN_PROGRESS',
        'IN_MID_CYCLE_REVIEW',
        'FINISHED',
        'EXCEPTUATED'
    ];

    $scope.init = function () {
        $scope.loggedUsername = $scope.loggedUser.email.toLowerCase();
        $scope.error = null;
        $scope.pending_performance_evaluations = null,
        PerformanceEvaluationService.GetPendingPerformanceEvaluations().then(function success(response) {
            $scope.pending_performance_evaluations = response.data.result;
            console.log($scope.pending_performance_evaluations)
        }, function error(_response){
            console.log('error GetPendingPerformanceEvaluations');
        });
        $scope.reloadSidebar();
        $scope.performanceEvaluations = [];
        $scope.subPerformanceEvaluations = [];
        $scope.getPeriods();
        $scope.getCollabs();
    }
   
    // Functions collapse
    $scope.collapsePendingClick = () => $scope.collapsePending = !($socpe.collapsePending);
    $scope.collapseFilterClick = () => $scope.collapseFilter = !($scope.collapseFilter);
    $scope.collapseGrillaClick = () => $scope.collapseGrilla = !($scope.collapseGrilla);
    $scope.isPendingCollapsed = () => $scope.collapsePending;
    $scope.isFilterCollapsed = () => $scope.collapseFilter;
    $scope.isGrillaCollapsed = () => $scope.collapseGrilla;
    // ---------------
    $scope.do_evaluation = function (evaluation) {
        if (evaluation.state !== 'PENDING' && evaluation.state !== 'IN_PROGRESS'){
            PerformanceEvaluationService.GetPerformanceEvaluation(evaluation.id).then(function success(response) {
                if ($scope.loggedUsername === response.data.evaluatorDto.email.toLowerCase()) {
                    $scope.currentStep = response.data.currentStepEvaluator
                } else {
                    if ($scope.loggedUsername === response.data.evaluatedDto.email.toLowerCase()) {
                        $scope.currentStep = response.data.currentStepEvaluatee
                    }
                }
                
                $localStorage.actualPerformanceId = evaluation.id;

                if(evaluation.evaluateeRole === 'ROLE_LEAD'){
                    console.log($scope.currentStep)
                    switch ($scope.currentStep) {
                        case 0:
                            $state.go('ltl-first-step', {evaluationId: evaluation.id});
                            break;
                        case 1:
                            $state.go('ltl-first-step-c', {evaluationId: evaluation.id});
                            break;
                        case 2:
                            //$state.go('ltl-second-step', {evaluationId: evaluation.id});
                            $state.go('ltl-fourth-step', {evaluationId: evaluation.id});
                            break;
                        case 3:
                            //$state.go('ltl-third-step', {evaluationId: evaluation.id});
                            $state.go('ltl-fourth-step', {evaluationId: evaluation.id});
                            break;
                        case 4:
                            $state.go('ltl-fourth-step', {evaluationId: evaluation.id});
                            break;
                        case 5:
                            $state.go('ltl-fifth-step-summary', {evaluationId: evaluation.id});
                            break;
                    }
                }
                else if(evaluation.evaluateeRole === 'ROLE_COLLABORATOR'){
                    switch ($scope.currentStep) {
                        case 0:
                            $state.go('ltl-first-step', {evaluationId: evaluation.id});
                            break;
                        case 1:
                            $state.go('ltc-first-step-c', {evaluationId: evaluation.id});
                            break;
                        case 2:
                            $state.go('ltc-second-step', {evaluationId: evaluation.id});
                            break;
                        case 3:
                            $state.go('ltc-third-step', {evaluationId: evaluation.id});
                            break;
                    }
                }
            }, function error(response) {
                console.log('error GetPE', response)
            })
        } 
    }

    $scope.isCurrentDateInRange = function(initialDateReview, finalDateReview) {
        let currentDate = new Date();
        let startDate = new Date(initialDateReview);
        let endDate = new Date(finalDateReview);

        return currentDate >= startDate && currentDate <= endDate;
    };

    $scope.getPeriods = function () {
        PeriodService.GetPeriods({}).then(function success(response) {
            $scope.periods = response.data.result;
            console.log(response.data.result)
        }, function error(response) {
            console.error('### error fetching periods ', response);
        });
    }

    $scope.getCollabs = function () {
        // Recuperamos los lideres directos e indirectos
        UsersService.GetAllCollaborators().then(function success(response){
                let allCollabs = response.data.result;
                $scope.leadCollaborators = allCollabs.filter(c => c.role === 'ROLE_LEAD');
                $scope.leadCollaborators.sort(function(a, b) {
                    if (a.fullname > b.fullname) {
                        return 1;
                      }
                      if (a.fullname < b.fullname) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                  });
            }, function error(response){
                console.log('error fetching collaborators', response);
            });

        // Recuperamos los colaboradores y lideres directos
        UsersService.GetCollaborators().then(function success(response){
            $scope.collaborators = response.data.result;
            $scope.collaborators.sort(function(a, b) {
                if (a.fullname > b.fullname) {
                    return 1;
                    }
                    if (a.fullname < b.fullname) {
                    return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
        }, function error(response){
            console.log('error fetching collaborators', response);
        });
    }

    $scope.makeEvent = function (evaluation_id) {
        var modal = $state.get('exclude-evaluation');
        modal.resolve.modalParams = function () {
          return {
              evaluation_id: evaluation_id
          };
        };
        modal.windowClass = 'modal-zindex';
        modal.size = 'lg';
        var modalInstance = $uibModal.open(modal);

        modalInstance.result.then(function () {
            PerformanceEvaluationService.GetPendingPerformanceEvaluations().then(function success(response) {
            $scope.pending_performance_evaluations = response.data.result;
        }, function error(_response){
            console.log('error GetPendingPerformanceEvaluations');
        });
        }, function () {
            //when error
        });  
    }
    $scope.includeEvaluation = function (evaluationId){
        swal({
          title: 'Incorporar evaluación',
          text: 'La evaluación será incorporada al período',
          type: 'info',
          showCancelButton: true,
          confirmButtonClass: 'btn-info',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          closeOnConfirm: true,
          closeOnCancel: true,
        },
          function (isConfirm) {
            if (isConfirm) {
              PerformanceEvaluationService.IncludePerformanceEvaluation(evaluationId).then(function success(response) {
                PerformanceEvaluationService.GetPendingPerformanceEvaluations().then(function success(response) {
                    $scope.pending_performance_evaluations = response.data.result;
                }, function(error){});
                swal('Evaluación incorporada', '', 'success');
              }, function error(error) {
                let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
                swal('No ha sido posible incorporar la evaluación.', errorText, 'error');
              });
            }
          });
      };

    // $scope.getEvaluationsFiltered = function () {
    //     let params = {};
    //     if($scope.selected_collaborator && $scope.selected_collaborator !== null) {
    //         params['collaborator'] = JSON.parse($scope.selected_collaborator).email;
    //     }
    //     if($scope.selected_period && $scope.selected_period !== null) {
    //         params['period'] = JSON.parse($scope.selected_period).id;
    //     }
    //     if($scope.selected_general_performance && $scope.selected_general_performance !== null) {
    //         params['generalPerformance'] = $scope.selected_general_performance;
    //     }
    //     params['state'] = 'FINISHED',
    //     PerformanceEvaluationService.GetEvaluations(params).then(function (success){
    //         $scope.performanceEvaluations = success.data.result;
    //         $scope.collapseGrilla = true;
    //     }, function (error){
    //         console.log('error fetching evaluations', error);
    //         swal('No es posible mostrar las evaluaciones', error.data.cause, 'error');
    //     })
    // }

    $scope.getEvaluationsFinished = function () {
        let params = {};
        if($scope.selected_collaborator && $scope.selected_collaborator !== null) {
            params['collaborator'] = JSON.parse($scope.selected_collaborator).email;
        }
        if($scope.selected_period && $scope.selected_period !== null) {
            params['period'] = JSON.parse($scope.selected_period).id;
        }
        if($scope.selected_general_performance && $scope.selected_general_performance !== null) {
            params['generalPerformance'] = $scope.selected_general_performance;
        }
        params['state'] = 'FINISHED',
        params['lookupAs'] = 'EVALUATOR',
        PerformanceEvaluationService.GetEvaluationsFinished(params).then(function (success){
            $scope.performanceEvaluations = success.data.result;
            $scope.collapseGrilla = true;
        }, function (error){
            console.log('error fetching evaluations', error);
            swal('No es posible mostrar las evaluaciones', error.data.cause, 'error');
        })

    }

    $scope.getSubEvaluationsFiltered = function () {
        let params = {};
        if($scope.selected_collaborator_2 && $scope.selected_collaborator_2 !== null) {
            params['collaboratorEmail'] = JSON.parse($scope.selected_collaborator_2).email;
        }
        if($scope.selected_period_2 && $scope.selected_period_2 !== null) {
            params['period'] = JSON.parse($scope.selected_period_2).id;
        }
        if($scope.selected_general_performance_2 && $scope.selected_general_performance_2 !== null) {
            params['generalPerformance'] = $scope.selected_general_performance_2;
        }
        if ($scope.selected_collaborator_fullname && $scope.selected_collaborator_fullname != '') {
            params['collaboratorFullname'] = $scope.selected_collaborator_fullname;
        }
        if ($scope.selected_state && $scope.selected_state != '') {
            params['state'] = $scope.selected_state;
        }
        PerformanceEvaluationService.GetSubEvaluations(params).then(function (success){
            $scope.subPerformanceEvaluations = success.data.result;
            $scope.collapseGrilla = true;
        }, function (error){
            console.log('error fetching evaluations', error);
            swal('No es posible mostrar las evaluaciones', error.data.cause, 'error');
        });
    };

    $scope.cleanFilter = function () {
        $scope.selected_collaborator = null;
        $scope.selected_period = null;
        $scope.selected_general_performance = null;
    };

    $scope.cleanFilter2 = function () {
        $scope.selected_collaborator_2 = null;
        $scope.selected_period_2 = null;
        $scope.selected_general_performance_2 = null;
    };

    
    $scope.watchEvaluation = function (evaluation) {
        if(evaluation.evaluateeRole === 'ROLE_LEAD'){
            let param = {evaluationId: evaluation.id, disabled: true};
            $state.go('ltl-fourth-step', param);
        }
        else if (evaluation.evaluateeRole === 'ROLE_COLLABORATOR'){
            let param = {evaluationId: evaluation.id, disabled: true};
            $state.go('ltc-third-step', param);
        }
    }

    $scope.print = function (evaluation) {
        PerformanceEvaluationService.DocumentPrint(evaluation.id).then( function success(response) {
            var newBlob = new Blob([response.data], {type: 'application/pdf'});
            const data = URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download='HB-' + evaluation.evaluateeFullname.replace(' ', '_') + '-' + evaluation.period.replace(' ', '_') + '.pdf';
            link.click();
            setTimeout(function(){
                URL.revokeObjectURL(data);
            }, 1000);
            $window.open(trash, '_blank');
        }, function error(error) {
            swal('No es posible procesar la solicitud', error.data.cause, 'error');
        });
    };

    $scope.watchSubEvaluation = function (evaluation) {
        if(evaluation.type === 'LEAD_LEAD'){
            let param = {evaluationId: evaluation.id, disabled: true};
            $state.go('ltl-fourth-step', param);
        }
        else if (evaluation.type === 'LEAD_COLLABORATOR'){
            let param = {evaluationId: evaluation.id, disabled: true};
            $state.go('ltc-third-step', param);
        }
    }

    $scope.reloadSidebar = function () {
        $rootScope.$broadcast('refresh');
    }
    $scope.init();

}]);