'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlFifthStepSummaryController', ['$scope', '$stateParams', '$state', '$rootScope', '$translate', '$uibModal', 'PerformanceEvaluationService', '$localStorage', function ($scope, $stateParams, $state, $rootScope, $translate, $uibModal, PerformanceEvaluationService, $localStorage) {
		$scope.loggedUsername = $scope.loggedUser.email.toLowerCase();
		$scope.evaluation = null;
		$scope.disabled = $stateParams.disabled;
		$scope.adminWatching = $stateParams.adminWatching;
		$scope.canSave = false;
		$scope.submittedRating = false;

		$scope.init = function () {
			$scope.incompleteFields = [];
			$scope.requiredFields = [
			];

			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}
			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				$scope.useObjectives = response.data.objectivesDto;
				$scope.useObjectives2 = response.data.objectivesBehaviorDto;

				$scope.evaluation.valuesCalification.sort((x, y) => $scope.compareValueName(x.valueName, y.valueName));
				$scope.loggedUsername = $scope.loggedUser.email.toLowerCase();
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee
						$scope.canSave = true;
					}
				}
				$scope.getEmptyFields();

				if ($scope.evaluation.generalPerformanceRating != null && $scope.evaluation.generalPerformanceRating.length > 0) {
					$scope.submittedRating = true;
				}

				formatDateToUse($scope.useObjectives);
				formatDateToUse($scope.useObjectives2);

				PerformanceEvaluationService.GetEvaluationStrategicDriverValues().then(function success(response) {
					$scope.evaluationStrategicDriverValues = response.data;
					handleStrategicDriverValues();
				}, function error(response) {
					console.error('Error al recuperar los valores del Enum EvaluationStrategicDriver', response);
				});

			}, function error(_error) {
				console.log('error fetching evaluation', id)
			})

		};

		$scope.continue_evaluation = function (evaluation) {

			$scope.deleteEmptyOkr($scope.useObjectives2);
			$scope.formatDate($scope.useObjectives);
			$scope.formatDate($scope.useObjectives2);

			$scope.evaluation.objectivesDto = $scope.useObjectives;

			if ($scope.evaluation.state === 'IN_MID_CYCLE_REVIEW') {
				$scope.evaluation.state = 'IN_LEADER_MID_CYCLE_REVIEW';
			}

			if (evaluation.leaderCommentsBehavior != null 
				&& evaluation.leaderCommentsObjectives != null 
				&& evaluation.leaderCommentsBehavior.length > 5
				&& evaluation.leaderCommentsObjectives.length > 5 
				&& evaluation.currentStepEvaluator == 5) {
				
				if (evaluation.collaboratorCommentsBehavior != null && evaluation.collaboratorCommentsBehavior.length > 5) {
					$scope.evaluation.state = 'PENDING_RATING_MID_CYCLE_REVIEW';
				} else {
					$scope.evaluation.state = 'PENDING_COLLAB_COMMENTS_MID_CYCLE_REVIEW';
				}
			}

			PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
				function success(_response) {
					if ($scope.loggedUsername === evaluation.evaluatorDto.email.toLowerCase()) {
						if ($scope.evaluation.generalPerformanceRating == null) {
							swal('Enviado', 'Revisión enviada con éxito', 'success');
						} else {
							swal('Enviado', 'Calificación de desempeño general enviada con éxito', 'success');
						}
					}
					$scope.goToConditional();
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					$scope.validationErrors = error.data.validationErrors;
					console.log(JSON.stringify($scope.validationErrors, undefined, 2))
					console.log(error)

					if (evaluation.leaderCommentsBehavior != null && evaluation.leaderCommentsObjectives != null && evaluation.leaderCommentsObjectives.length > 0 && evaluation.leaderCommentsBehavior.length > 0) {
						$scope.evaluation.state = 'IN_LEADER_MID_CYCLE_REVIEW';
					}
				}
			);
		};

		$scope.confirm_notification = function (evaluation) {

			$scope.deleteEmptyOkr($scope.useObjectives2);
			$scope.formatDate($scope.useObjectives);
			$scope.formatDate($scope.useObjectives2);

			$scope.evaluation.objectivesDto = $scope.useObjectives;
			$scope.evaluation.confirmedNotification = true;
			
			console.log($scope.evaluation)
			PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
				function success(_response) {
					swal('Confirmado', 'Comunicación confirmada con éxito', 'success');
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					$scope.validationErrors = error.data.validationErrors;
					console.log(JSON.stringify($scope.validationErrors, undefined, 2))
					console.log(error)
				}
			);
		};

		$scope.save = function (evaluation) {

			if ($scope.canSave){
				$scope.deleteEmptyOkr($scope.useObjectives);
				$scope.formatDate($scope.useObjectives2);
				$scope.formatDate($scope.useObjectives);

				$scope.evaluation.objectivesDto = $scope.useObjectives;

				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
					$scope.evaluation.currentStepEvaluator = 1;
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
						$scope.evaluation.currentStepEvaluatee = 4;
					}
				}

				PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
					function success(_response) {
						swal('Guardada', 'Evaluación guardada con éxito', 'success');
					}, function error(error) {
						let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
						swal('Error al guardar la evaluación.', errorText, 'error');
						console.log('failure in continue evaluation-1');
					}
				);
			}
		};

		$scope.confirm_evaluation = function (evaluation) {
			swal({
				title: 'Finalizar planificación',
				text: 'Planificación completada por ambas partes. ¿Desea finalizarla?',
				type: 'info',
				showCancelButton: true,
				confirmButtonClass: 'btn-info',
				confirmButtonText: 'Confirmar',
				cancelButtonText: 'Cancelar',
				closeOnConfirm: true,
				closeOnCancel: true,
			},
				function (isConfirm) {
					if (isConfirm) {
						$scope.formatDate($scope.useObjectives);
						$scope.formatDate($scope.useObjectives2);
						$scope.evaluation.state = 'COMPLETED_MID_CYCLE_REVIEW';

						PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
							(success) => {
								swal('¡Planificación completada con éxito!', '', 'success');
								$scope.goToConditional();
							},
							(error) => {
								if(error.data.cause !== undefined){
									$scope.evaluation.state = 'IN_LEADER_MID_CYCLE_REVIEW';
									
									let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
									swal('Error al finalizar la planificación', errorText, 'error');
									$scope.validationErrors = error.data.validationErrors;
								}else{
									swal('Error al finalizar la planificación', 'Error desconocido', 'error')
								}
								console.log('failure in continue evaluation-1', error);
							})
					}
				});
		};

		$scope.review_objectives = function (evaluation){
			swal({
				title: 'Revisar planificación',
				text: `Planificación completada por ${$scope.evaluation.evaluatedDto.fullname}. ¿Desea revisarla?`,
				type: 'info',
				showCancelButton: true,
				confirmButtonClass: 'btn-info',
				confirmButtonText: 'Confirmar',
				cancelButtonText: 'Cancelar',
				closeOnConfirm: true,
				closeOnCancel: true,
			},
				function (isConfirm) {
					if (isConfirm) {
						$scope.formatDate($scope.useObjectives);
						$scope.formatDate($scope.useObjectives2);
						$scope.evaluation.state = 'IN_MID_CYCLE_REVIEW';

                        console.log($scope.evaluation)

						PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
							(success) => {
								console.log(JSON.stringify('State sent: '+$scope.evaluation.state));
								swal('¡Planificación a revisión!', '', 'success');
								$scope.goToConditional();
							},
							(error) => {
								if(error.data.cause !== undefined){
									let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
									swal('Error al enviar la planificación a revisión', errorText, 'error');
									$scope.validationErrors = error.data.validationErrors;
								}else{
									swal('Error al enviar la planificación a revisión', 'Error desconocido', 'error')
								}
								console.log('failure in continue evaluation-1', error);
							})
						}
						else {
							$state.go('dashboard')
						}
				});
		}

		function handleStrategicDriverValues(){
			$scope.getStrategicDriver = function (objective){
				let value = $scope.evaluationStrategicDriverValues.find(element => element.name === objective.strategicDriver).value;
				return value;
			}
		}

		$scope.compareValueName = function (a, b) {
			if (a.valueName == b.valueName) {
				return $scope.compareAspectName(a, b);
			}
			if (a.valueName < b.valueName) {
				return -1;
			}
			if (a.valueName > b.valueName) {
				return 1;
			}
		}

		$scope.compareAspectName = function (a, b) {
			if (a.aspectName < b.aspectName) {
				return -1;
			}
			if (a.aspectName > b.aspectName) {
				return 1;
			}
			return 0;
		}

		$scope.isEvaluator = function () {
			return $scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase();
		}

		$scope.hasEmptyFieldsInObjectivesDto = function(objectivesDto) {
			for (let i = 0; i < objectivesDto.length; i++) {
				const objective = objectivesDto[i];
				if (!objective.progressStatement || !objective.rating || !objective.observations) {
					return true; 
				}
			}
			return false; 
		}

		$scope.getEmptyFields = function () {		
			$scope.requiredFields.forEach(function (x) {
				 if (!$scope.evaluation[x.field]){
					$scope.incompleteFields.push(x);
				 }
			});
		}

		//ver si cambiar
		$scope.collaborator_can_see = function (evaluationState) {
			var validAdmin = $localStorage.loggedUser.isAdmin;
			var validLider = $localStorage.loggedUser.isLeader && $scope.loggedUserRoleInEval === 'EVALUATOR';
			var validColab = $localStorage.loggedUser.isCollaborator && $scope.loggedUserRoleInEval === 'EVALUATEE' && ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes(evaluationState);
			return (validAdmin || validLider || validColab);
		}

		$scope.goToConditional = function () {
			let isEvaluator = $scope.isEvaluator();
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else {
				$state.go('my-evaluation');
			}
		}

		$scope.back = function (id, _evaluation) {
			let isEvaluator = $scope.isEvaluator();
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else if ($scope.disabled && !(isEvaluator) || (!(isEvaluator) && $scope.evaluation.state == 'IN_LEADER_MID_CYCLE_REVIEW') || (!(isEvaluator) && $scope.evaluation.state == 'PENDING_RATING_MID_CYCLE_REVIEW') || $scope.evaluation.state == 'COMPLETED_MID_CYCLE_REVIEW') {
				$state.go('my-evaluation');
			}
			else {
                $state.go('ltl-fifth-step', { evaluationId: id });
            }
		}

		$scope.close = function (_id, _evaluation) {
			let isEvaluator = $scope.isEvaluator();
			console.log('asdf', $scope.adminWatching);
			if ($scope.adminWatching) {
				$state.go('search-evaluation');
				return;
			}
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else if (!(isEvaluator)) {
				$state.go('my-evaluation');
			}
		}

		$scope.formatDate = function (useObjectives){
			for (let i=0; i < useObjectives.length; i++){
				for (let j=0; j< useObjectives[i].keyResults.length; j++){
					let date = useObjectives[i].keyResults[j].timeLimit;
					date = moment(date);
					useObjectives[i].keyResults[j].timeLimit = date.format('YYYY-MM-DD');
				}
			}
		}

		function formatDateToUse(objectives){
			for (let i=0; i < objectives.length; i++){
				for (let j=0; j< objectives[i].keyResults.length; j++){
					let date = objectives[i].keyResults[j].timeLimit;
					objectives[i].keyResults[j].timeLimit = new Date(date);
				}
			}
		}

		$scope.deleteEmptyOkr = function (objectives) {
			for (let i = objectives.length - 1; i >= 0; i--) {
				var objective = objectives[i];
				if (objective.description === '' && objective.complianceDegree === 0 && objective.keyResults.length === 0) {
					objectives.splice(i, 1);
				}
			}
		}

		$scope.isCurrentDateInRange = function(initialDateReview, finalDateReview) {
			let currentDate = new Date();
			let startDate = new Date(initialDateReview);
			let endDate = new Date(finalDateReview);
	
			return currentDate >= startDate && currentDate <= endDate;
		};

		$scope.init();

	}]);
