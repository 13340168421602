angular.module("app.config", [])

.constant("RESTAPI_VERSION", "/v1")

.constant("RESTAPI_URL", "https://int.empower-us.com")

.constant("OAUTH_PROVIDER_CLIENT_ID", "984200124091-i75tcrep4h8r7q2k7480ub07fe5pb1b3.apps.googleusercontent.com")

.constant("OAUTH_PROVIDER_CALLBACK", "int.empower-us.com/v1/callback")

.constant("OAUTH_PROVIDER_SCOPES", [
	"profile",
	"email"
])

.constant("LOG_LEVEL", "DEBUG")

.constant("AZURE_CLIENT_ID", "1d7645b8-9d4d-492c-8d6b-7cb1589dd5fd")

.constant("AZURE_AUTHORITY", "498dee66-31d7-4fe1-98db-a1a781d3c645")

.constant("AZURE_REDIRECT_URI", "https://int.empower-us.com")

.constant("OAUTH_AZURE_LOGIN_URL", "https://int.empower-us.com/v1/api/secure-data")

;