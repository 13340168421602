'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlFirstStepController', ['$scope', '$stateParams', '$state', '$rootScope', '$translate', 'PerformanceEvaluationService', '$localStorage', '$anchorScroll', function ($scope, $stateParams, $state, $rootScope, $translate, PerformanceEvaluationService, $localStorage, $anchorScroll) {

		$scope.init = function () {
			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}
			$scope.currentState = $state;
			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				$scope.useObjectives = $scope.evaluation.objectivesDto;
				// $scope.useObjectives2 = $scope.evaluation.objectivesBehaviorDto;
				$scope.loggedUsername = $scope.loggedUser.email.toLowerCase();
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator;
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee;
					}
				}
				$scope.canSave = false;
				$scope.verifyCanSave();
				
				if ($scope.useObjectives.length === 0){
					$scope.newOkr();
				} else if ($scope.useObjectives.length === 5){
					$scope.showAddObjective = false;
				}

				$scope.showAddKeyResult = false;
				$scope.showAddKeyResultLim = true;
				$scope.showAddObjective = true;
				$scope.driverIndex = -1;
				
				$scope.formatDateToUse($scope.useObjectives);

				for (let i=0; i <$scope.useObjectives.length; i++){
					if ($scope.useObjectives[i].keyResults.length === 0){
						$scope.newKeyResult(i);
					}
				}
				
				$scope.minDate =  new Date().toISOString().split('T')[0];

			}, function error(_error) {
				//console.log('error fetching evaluation', id)
				console.log('error fetching evaluation', $scope.evalId)
			})

			PerformanceEvaluationService.GetEvaluationStrategicDriverValues().then(function success(response) {
				$scope.evaluationStrategicDriverValues = response.data;
			}, function error(response) {
				console.error('Error al recuperar los valores del Enum EvaluationStrategicDriver', response);
			});
		};

		$scope.verifyCanSave = function (){
			$scope.canSave = false;
			if ($scope.loggedUserRoleInEval === 'EVALUATOR'){
				if ($scope.evaluation.currentStepEvaluator === 0){
					$scope.canSave = true;
				}
			} else if ($scope.loggedUserRoleInEval === 'EVALUATEE'){
				if ($scope.evaluation.currentStepEvaluatee === 0){
					$scope.canSave = true;
				}
			}
		}

		$scope.save = function (evaluation) {

			if ($scope.canSave){
				$scope.deleteEmptyOkr();
				$scope.formatDate($scope.useObjectives);
	
				// $scope.evaluation.objectivesBehaviorDto = $scope.useObjectives2;
	
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
					$scope.evaluation.currentStepEvaluator = 0;
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
						$scope.evaluation.currentStepEvaluatee = 0;
					}
				}
	
				PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
					function success(_response) {
						swal('Guardada', 'Evaluación guardada con éxito', 'success');
					}, function error(error) {
						let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
						swal('Error al guardar la evaluación.', errorText, 'error');
						console.log('failure in continue evaluation-1');
					}
				);
			}
		};

		$scope.continue_evaluation = function (evaluation) {

			$scope.deleteEmptyOkr();
			$scope.formatDate($scope.useObjectives);

			// $scope.evaluation.objectivesBehaviorDto = $scope.useObjectives2;
			$scope.submitted = true;
			
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
				$scope.evaluation.currentStepEvaluator = 0;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
					$scope.evaluation.currentStepEvaluatee = 0;
				}
			}
			PerformanceEvaluationService.PostPerformanceEvaluation(evaluation).then(
				function success(_response) {
					$state.go('ltl-first-step-c', { evaluationId: evaluation.id });
					$anchorScroll();
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					$scope.validationErrors = error.data.validationErrors;
					console.log('failure in continue evaluation-1');
				}
			);

		};

		$scope.back = function (_id, _evaluation) {
			// TODO ask to POST evaluation ?
			$state.go('performance-evaluation');
		}

		$scope.toComp = function (_id, _evaluation) {
			$state.go('ltl-first-step-c');
		}

		$scope.newOkr = function () {
			
			if ($scope.useObjectives.length >= 5) {
				swal('¡Advertencia!', 'No se pueden agregar más de 5 Objetivos.', 'warning');
			} else {
				let emptyOkr = {
					'description': '',
					'complianceDegree': 0,
					'keyResults': [],
					'strategicDriver': ''
				};
				$scope.useObjectives.push(emptyOkr);
				$scope.newKeyResult($scope.useObjectives.length - 1);
		
				// Si se alcanza el límite máximo de Okr (5), se oculta el botón de agregar
				if ($scope.useObjectives.length === 5) {
					$scope.showAddObjective = false;
				}
			}
		}

		$scope.newKeyResult = function (index) {

			if ($scope.useObjectives[index].keyResults.length >= 5) {
				swal('¡Advertencia!', 'No se pueden agregar más de 5 Iniciativas Clave.', 'warning');
			} else {
				let emptyKeyResult = {
					'description': '',
					'calification': 0,
					'timeLimit': new Date()
				}
				$scope.useObjectives[index].keyResults.push(emptyKeyResult);
				$scope.showAddKeyResult = false;
				
				// Verificar si se alcanzó el límite para mostrar u ocultar el botón de agregar
				if ($scope.useObjectives[index].keyResults.length === 5) {
					$scope.showAddKeyResultLim = false;
				}
			}
		}

		$scope.collaborator_can_see = function (evaluation) {
			return ($scope.loggedUserRoleInEval === 'EVALUATOR')
			|| (($scope.loggedUserRoleInEval === 'EVALUATEE') 
			&& ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes($scope.evaluation.state));
		}

		$scope.deleteObjective = function (index) {
			$scope.useObjectives.splice(index, 1);

			if ($scope.useObjectives.length<5){
				$scope.showAddObjective = true;
			}
		}

		$scope.deleteKeyResult = function (index, keyResultIndex) {
			$scope.useObjectives[index].keyResults.splice(keyResultIndex, 1);

			//al eliminar todas las iniciativas clave, se muestra el icono de agregar una iniciativa clave abajo
			if ($scope.useObjectives[index].keyResults.length === 0){
				$scope.showAddKeyResult = true;
			}

			if($scope.useObjectives[index].keyResults.length<5){
				$scope.showAddKeyResultLim = true;
			}
		}

		$scope.formatDate = function (useObjectives){
			for (let i=0; i < useObjectives.length; i++){
				for (let j=0; j< useObjectives[i].keyResults.length; j++){
					let date = useObjectives[i].keyResults[j].timeLimit;
					date = moment(date);
					useObjectives[i].keyResults[j].timeLimit = date.format('YYYY-MM-DD');
				}
			}
		}

		$scope.formatDateToUse = function (useObjectives){
			for (let i=0; i < useObjectives.length; i++){
				for (let j=0; j< useObjectives[i].keyResults.length; j++){
					let date = useObjectives[i].keyResults[j].timeLimit;
					useObjectives[i].keyResults[j].timeLimit = new Date(date);
				}
			}
		}

		$scope.deleteEmptyOkr = function () {
			for (let i = $scope.useObjectives.length - 1; i >= 0; i--) {
				var objective = $scope.useObjectives[i];
				if (objective.description === '' && objective.complianceDegree === 0 && objective.keyResults.length === 0) {
					$scope.useObjectives.splice(i, 1);
				}
			}
		}

		$scope.checkComplianceDegree = function(objective) {
			if (objective.complianceDegree < 15 || objective.complianceDegree > 40) {
				$scope.showComplianceDegreeWarning = true;
			} else {
				$scope.showComplianceDegreeWarning = false;
			}
		};

		$scope.checkComplianceDegreeRange = function (objectives) {
			for (let i = 0; i < objectives.length; i++) {
				if (objectives[i].complianceDegree < 15 || objectives[i].complianceDegree > 40) {
					return false;
				}
			}
			return true;
		}

		$scope.checkOkrLim = function (objectives) {
			if (objectives.length < 3 || objectives.length >5){
				return false;
			}
			return true;
		}

		$scope.checkSumComplianceDegree = function (objectives) {
			$scope.sumComplianceDegree = 0;
			if (objectives){
				for (let i = 0; i < objectives.length; i++) {
					$scope.sumComplianceDegree = $scope.sumComplianceDegree + objectives[i].complianceDegree;
				} 
			}	
			return $scope.sumComplianceDegree;
		}

		$scope.isSumComplianceDegreeOk = function (objectives) {
			let sum = 0;
			for (let i = 0; i < objectives.length; i++){
				sum = sum + objectives[i].complianceDegree;
			}
			if (sum === 100){
				return true;
			}
			return false;
		}

		$scope.checkStrategicDriverRep = function (objectives) {
			for (let i = 0; i < objectives.length; i++) {
				for (let j = i + 1; j < objectives.length; j++) {
					if (objectives[i].strategicDriver === objectives[j].strategicDriver) {
						return false;
					}
				}
			}
			return true;
		}

		$scope.checkStrategicDriver = function (index) {
			$scope.driverIndex = -1;
			let selectedValue = $scope.useObjectives[index].strategicDriver;

			for (let i = 0; i < $scope.useObjectives.length; i++){
				if (selectedValue == $scope.useObjectives[i].strategicDriver){
					if (index !== i){
						$scope.driverIndex = index;
						return true;
					}
				}
			}
			return false;
		}

		$scope.init();

	}]);