'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlFirstStep1Controller', ['$scope', '$stateParams', '$state', '$rootScope', '$translate', 'PerformanceEvaluationService', '$localStorage', '$anchorScroll', function ($scope, $stateParams, $state, $rootScope, $translate, PerformanceEvaluationService, $localStorage, $anchorScroll) {

		$scope.init = function () {
			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}
			$scope.currentState = $state;

			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				// $scope.useObjectives = $scope.evaluation.objectivesDto;
				$scope.useObjectives2 = $scope.evaluation.objectivesBehaviorDto;
				$scope.loggedUsername = $scope.loggedUser.email.toLowerCase();
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee
					}
				}
				$scope.canSave = false;
				$scope.verifyCanSave();
			
				$scope.showAddObjective = true;
				$scope.showTextBehavior = false;
				$scope.behaviorIndex = -1;
				$scope.getValueText = [];

				if ($scope.useObjectives2.length === 0){
					$scope.newOkr();
				} else if ($scope.useObjectives2.length === 2){
					$scope.showAddObjective = false;
				}

				// $scope.formatDateToUse($scope.useObjectives);
				$scope.formatDateToUse($scope.useObjectives2);
				
				$scope.minDate =  new Date().toISOString().split('T')[0];

                PerformanceEvaluationService.GetEvaluationBehaviorValues().then(function success(response) {
                    $scope.evaluationBehaviorValues = response.data;
                    handleEvaluationBehaviorValues();
                }, function error(response) {
                    console.error('Error al recuperar los valores del Enum EvaluationBehavior', response);
                });

			}, function error(_error) {
				//console.log('error fetching evaluation', id)
				console.log('error fetching evaluation', $scope.evalId)
			})
		};

        function handleEvaluationBehaviorValues() {
			
            if ($scope.evaluationBehaviorValues && Array.isArray($scope.evaluationBehaviorValues) && $scope.useObjectives2 && Array.isArray($scope.useObjectives2)) {
                for (let i = 0; i < $scope.useObjectives2.length; i++){
                    let values = $scope.evaluationBehaviorValues.find(element => element.valueTitle === $scope.useObjectives2[i].description);
					if (values) {
						$scope.getValueText[i] = values.valueTexts;
						if ($scope.getValueText[i].length > 0){
							$scope.showTextBehavior = true;
						}
					}
                }
            } else {
                console.error('Error: $scope.evaluationBehaviorValues or $scope.useObjectives2 is undefined or not an array');
            }
        }

		$scope.verifyCanSave = function (){
			$scope.canSave = false;
			if ($scope.loggedUserRoleInEval === 'EVALUATOR'){
				if ($scope.evaluation.currentStepEvaluator === 1){
					$scope.canSave = true;
				}
			} else if ($scope.loggedUserRoleInEval === 'EVALUATEE'){
				if ($scope.evaluation.currentStepEvaluatee === 1){
					$scope.canSave = true;
				}
			}
		}

		$scope.save = function (evaluation) {

			if ($scope.canSave){
				$scope.deleteEmptyOkr($scope.useObjectives2);
				// $scope.formatDate($scope.useObjectives);
				$scope.formatDate($scope.useObjectives2);

				$scope.evaluation.objectivesBehaviorDto = $scope.useObjectives2;

				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
					$scope.evaluation.currentStepEvaluator = 1;
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
						$scope.evaluation.currentStepEvaluatee = 1;
					}
				}

				PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
					function success(_response) {
						swal('Guardada', 'Evaluación guardada con éxito', 'success');
					}, function error(error) {
						let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
						swal('Error al guardar la evaluación.', errorText, 'error');
						console.log('failure in continue evaluation-1');
					}
				);
			}
		};

		$scope.continue_evaluation = function (evaluation) {

			$scope.deleteEmptyOkr($scope.useObjectives2);
			// $scope.formatDate($scope.useObjectives);
			$scope.formatDate($scope.useObjectives2);

			$scope.evaluation.objectivesBehaviorDto = $scope.useObjectives2;

			$scope.submitted = true;
			
			if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
				$scope.evaluation.currentStepEvaluator = 1;
			} else {
				if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase() && $scope.currentStepEvaluatee === 3){
					$scope.evaluation.currentStepEvaluatee = 4;
				} else if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
					$scope.evaluation.currentStepEvaluatee = 1;
				} 
			}
			PerformanceEvaluationService.PostPerformanceEvaluation(evaluation).then(
				function success(_response) {
					$state.go('ltl-fourth-step', { evaluationId: evaluation.id });
					$anchorScroll();
				}, function error(error) {
					let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
					swal('Error al guardar la evaluación.', errorText, 'error');
					$scope.validationErrors = error.data.validationErrors;
					console.log(JSON.stringify($scope.validationErrors, undefined, 2))
					console.log('failure in continue evaluation-1');
				}
			);
		};

		$scope.backToObjectives = function (_id, _evaluation) {
			$state.go('ltl-first-step');
		}

		$scope.collaborator_can_see = function (evaluation) {
			return ($scope.loggedUserRoleInEval === 'EVALUATOR')
			|| (($scope.loggedUserRoleInEval === 'EVALUATEE') 
			&& ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes($scope.evaluation.state));
		}

		$scope.formatDate = function (useObjectives){
			for (let i=0; i < useObjectives.length; i++){
				for (let j=0; j< useObjectives[i].keyResults.length; j++){
					let date = useObjectives[i].keyResults[j].timeLimit;
					date = moment(date);
					useObjectives[i].keyResults[j].timeLimit = date.format('YYYY-MM-DD');
				}
			}
		}

		$scope.formatDateToUse = function (useObjectives){
			for (let i=0; i < useObjectives.length; i++){
				for (let j=0; j< useObjectives[i].keyResults.length; j++){
					let date = useObjectives[i].keyResults[j].timeLimit;
					useObjectives[i].keyResults[j].timeLimit = new Date(date);
				}
			}
		}

		$scope.deleteEmptyOkr = function (objectives) {
			for (let i = objectives.length - 1; i >= 0; i--) {
				var objective = objectives[i];
				if (objective.description === '' && objective.complianceDegree === 0 && objective.keyResults.length === 0) {
					objectives.splice(i, 1);
				}
			}
		}

		$scope.newOkr = function () {
			if ($scope.useObjectives2.length >= 2) {
				swal('¡Advertencia!', 'No se pueden agregar más de 2 comportamientos.', 'warning');
			} else {
				let emptyOkr = {
					'description': '',
					'complianceDegree': 0,
					'keyResults': []
				};
				$scope.useObjectives2.push(emptyOkr);
				$scope.newKeyResult($scope.useObjectives2.length - 1);
		
				if ($scope.useObjectives2.length === 2) {
					$scope.showAddObjective = false;
				}
			}
		}

		$scope.newKeyResult = function (index) {
			let emptyKeyResult = {
				'description': '',
				'calification': 0,
				'timeLimit': new Date()
			}
			$scope.useObjectives2[index].keyResults.push(emptyKeyResult);
		}

		$scope.deleteObjective = function (index) {
            deleteBehavior(index);
			$scope.useObjectives2.splice(index, 1);
			if ($scope.useObjectives2.length<2){
				$scope.showAddObjective = true;
			}
		}

        function deleteBehavior (index){
            $scope.getValueText[index] = '';
        }

		$scope.checkTextBehavior = function (index) {
			let selectedValue = $scope.useObjectives2[index].description;
            if (selectedValue === undefined){
                $scope.getValueText[index] = '';
				$scope.showTextBehavior = false;
				return true;
            } else if (selectedValue === ''){
				$scope.getValueText[index] = '';
				$scope.showTextBehavior = false;
				return true;
            } else {
                for (let i = 0; i < $scope.evaluationBehaviorValues.length; i++){
					if (selectedValue == $scope.evaluationBehaviorValues[i].valueTitle){
						$scope.showTextBehavior = true;
						$scope.getValueText[index] = $scope.evaluationBehaviorValues[i].valueTexts;
						return true;
					}
				}
            }
		}

        $scope.checkBehaviorRep = function (index) {
            $scope.behaviorIndex = -1;
			let selectedValue = $scope.useObjectives2[index].description;
			for (let i = 0; i < $scope.useObjectives2.length; i++){
				if (selectedValue == $scope.useObjectives2[i].description && selectedValue !== ''){
					if (index !== i){
                        $scope.behaviorIndex = index;
						return true;
					}
				}
			}
			return false;
		}

		$scope.init();

	}]);