'use strict';

angular.module('configService', ['app.config', 'constants'])

    .factory('restApiUrl', ['RESTAPI_URL', 'RESTAPI_VERSION', function restApiUrlFactory(RESTAPI_URL, RESTAPI_VERSION) {

        var service = {};
        service.GetGHDModuleURL = GetGHDModuleURL;

        function GetGHDModuleURL() {
            return RESTAPI_URL + RESTAPI_VERSION;
        }

        return service.GetGHDModuleURL();
    }])


    .factory('responseObserver',['$q','$location','$rootScope','$localStorage',function($q,$location,$rootScope,$localStorage){
        return {
            response: function(response){
                if (response.status === 401) {
                    console.log('Response 401');
                }
                return response || $q.when(response);
            },
            responseError: function(rejection) {
                if (rejection.status === 401) {
                    console.log('Response Error 401',rejection);
                    $rootScope.loggedUser = undefined;
                    $localStorage.loggedUser = undefined;
                    $location.path('/login');
                }
                return $q.reject(rejection);
            }
        }
    }])


    .config(['$httpProvider', '$logProvider', 'blockUIConfig', 'LOG_LEVEL', function ($httpProvider, $logProvider, blockUIConfig, LOG_LEVEL) {

        //Catch error responses ( 401,403 )
        $httpProvider.interceptors.push('responseObserver');

        $httpProvider.interceptors.push(function ($localStorage) {
            return {
          
              // Add an interceptor for requests.
              'request': function (config) {
                config.headers = config.headers || {}; // Default to an empty object if no headers are set.
          
                // Set the header if the token is stored.
                if($localStorage.loggedUser) {
                  config.headers['Authorization'] = $localStorage.loggedUser.token;
                  config.headers['LogginType'] = $localStorage.loggedUser.loggedType;
                }
      
                return config;
              }
            };
          });

        //Enabled log debug ( use always $log.debug for develop )
        if (LOG_LEVEL === 'DEBUG') {
            $logProvider.debugEnabled(true);
        } else {
            $logProvider.debugEnabled(false);
        }

        //Change the default template
        blockUIConfig.templateUrl = 'shared/util/block-ui-template.html';

        // Change the default overlay message
        blockUIConfig.message = 'Test ...';

        // Change the default delay before the blocking is visible
        blockUIConfig.delay = 250;

    }])
    

    .config(['$authProvider','OAUTH_PROVIDER_CLIENT_ID', 'OAUTH_PROVIDER_CALLBACK', 'OAUTH_PROVIDER_SCOPES' , function ($authProvider, OAUTH_PROVIDER_CLIENT_ID, OAUTH_PROVIDER_CALLBACK, OAUTH_PROVIDER_SCOPES) {
        $authProvider.google({
            clientId: OAUTH_PROVIDER_CLIENT_ID,
            url: OAUTH_PROVIDER_CALLBACK,
            scope: OAUTH_PROVIDER_SCOPES
        });
    }])

    
    .run(['$rootScope', '$location', '$localStorage', '$auth','AZURE_CLIENT_ID','AZURE_AUTHORITY','AZURE_REDIRECT_URI','jwtHelper', function run($rootScope, $location, $localStorage, $auth,AZURE_CLIENT_ID,AZURE_AUTHORITY,AZURE_REDIRECT_URI,jwtHelper) {
        // Config for msal
        var msalConfig = {
            auth: {
                clientId: AZURE_CLIENT_ID, 
                authority: 'https://login.microsoftonline.com/'+AZURE_AUTHORITY, 
                redirectUri: AZURE_REDIRECT_URI, 
            },
            cache: {
              cacheLocation: 'localStorage',
              storeAuthStateInCookie: false,
            }
        };
        $rootScope.msalInstance = new msal.PublicClientApplication(msalConfig);
        $rootScope.msalInstance.initialize();

        var isAuthenticated = function() {
			return $auth.isAuthenticated() || $localStorage.loggedUser &&  $localStorage.loggedUser.loggedType === 'Microsoft' &&  $localStorage.loggedUser.token;
        };

        // redirect to login page if not logged in and trying to access a restricted page
        $rootScope.$on('$locationChangeStart', function (event, next, current) {
            var publicPages = ['/login'];
            var restrictedPage = publicPages.indexOf($location.path()) === -1;
            var expiredToken = false;

            //Check user sesion data and redirect.
            if (!isAuthenticated() && $location.path() === '/login') {
                $rootScope.loggedUser = undefined;
            } else if (isAuthenticated()) {
                $rootScope.loggedUser = $localStorage.loggedUser;
                $rootScope.$broadcast('logguedUser');
                $location.path('/dashboard');
            } else if (!isAuthenticated() && restrictedPage) {
                $rootScope.loggedUser = $localStorage.loggedUser;
                $location.path('/login');
            } else {
                $rootScope.loggedUser = $localStorage.loggedUser;
            }
        });

    }]);
