'use strict';

angular.module('evaluation-period', [])

.config([ '$stateProvider', function($stateProvider) {
    $stateProvider.state('evaluation-period', {
        url: '/evaluation-period',
        templateUrl: 'components/evaluation_period/evaluation-period.html',
        controller: 'EvaluationPeriodCtrl'
    })
		.state('edit-period', {
			url: '/edit-period',
			templateUrl: 'components/evaluation_period/edit-period.html',
			controller: 'EditEvaluationPeriodCtrl',
			resolve: {}
		});
  }])

.controller('EvaluationPeriodCtrl', ['$scope', 'PeriodService', '$uibModal', '$state', function($scope, PeriodService, $uibModal, $state) {

  $scope.periodTitle = '';
  $scope.periodTo = null;
  $scope.periodFrom = null;
  $scope.periodReviewTo = null;
  $scope.periodReviewFrom = null;
  $scope.periodDuration = null; 
  $scope.filter = {};

  $scope.init = function () {
    $scope.periods = null;
    $scope.getPeriods();
    $scope.initFilter();
	};

  $scope.$watch('periodDuration', function (newValue, _oldValue) {
    if(newValue != undefined) {
      $scope.periodTo = moment($scope.periodFrom).add(newValue, 'month');
    }
  });

  $scope.$watch('periodFrom', function (newValue, _oldValue) {
    if($scope.periodDuration != undefined){
      $scope.periodTo = moment($scope.periodFrom).add(newValue, 'month');
    }
  });

  $scope.initFilter = function () {
		$scope.filter.dateFrom = null; //moment().subtract(30, 'days').toDate();
		$scope.filter.dateTo = null; //moment().toDate();
  };

  $scope.createPeriod = function () {
    let from = moment($scope.periodFrom).format();
    let to = moment($scope.periodTo).format();
    let reviewFrom = moment($scope.periodReviewFrom).format();
    let reviewTo = moment($scope.periodReviewTo).format();
    let body = {
      'title': $scope.periodTitle,
      'initialDate': from,
      'finalDate': to,
      'initialDateReview': reviewFrom,
      'finalDateReview': reviewTo
    };
    PeriodService.PostPeriod(body).then(function success(response) {
      $scope.getPeriods();
      $scope.deleteForm();
      swal('Período creado exitosamente.', '', 'success');
    }, function error(error) {
      swal('No es posible crear el período', error.data.cause, 'error');
      console.error('error saving perod', error);
    });
  };

  $scope.deleteForm = function () {
    $scope.periodTitle = '';
    $scope.periodTo = null;
    $scope.periodFrom = null;
    $scope.periodDuration = null;  
    $scope.periodReviewTo = null;
    $scope.periodReviewFrom = null;
  };

  $scope.getPeriods = function () {
    let params = {};
    
    if($scope.filter.dateFrom && $scope.filter.dateFrom != null) {
      params['from'] = moment($scope.filter.dateFrom).format();
    };

    if($scope.filter.dateTo && $scope.filter.dateTo != null) {
      params['to'] = moment($scope.filter.dateTo).format();
    };

    PeriodService.GetPeriods(params).then(function success(response) {
      $scope.periods = response.data.result;
    }, function error(response) {
      console.error('### error fetching periods ', response);
    });
  };

  $scope.deletePeriod = function (periodId) {
    PeriodService.DeletePeriod(periodId).then(function (response) {
      $scope.getPeriods();
      swal('Período eliminado exitosamente.', '', 'success');
    }, function(error) {
      swal('No es posible eliminar el período', error.data.cause, 'error');
    });
  };

  $scope.editPeriod = function (period) {
    var modal = $state.get('edit-period');
    modal.resolve.modalParams = function () {
      return {
        period: period
      };
    };
    modal.windowClass = 'modal-zindex';
    modal.size = 'lg';

    var modalInstance = $uibModal.open(modal);
    modalInstance.result.then(function () {
      // when close
      $scope.getPeriods();
    }, function () {
      // when error
    });
  } 

  $scope.init();
}])


.controller('EditEvaluationPeriodCtrl', ['$scope', 'PeriodService', '$uibModalInstance', 'modalParams', function($scope, PeriodService, $uibModalInstance, modalParams) {

	$scope.minDate = null;

  $scope.init = function () {
    $scope.period = angular.copy(modalParams.period); //clonamos el objeto
    $scope.period.initialDate = moment($scope.period.initialDate, 'YYYY-MM-DD').toDate()
    $scope.period.finalDate = moment($scope.period.finalDate, 'YYYY-MM-DD').toDate()
    $scope.period.initialDateReview = $scope.period.initialDateReview ? moment($scope.period.initialDateReview, 'YYYY-MM-DD').toDate() : '';
    $scope.period.finalDateReview = $scope.period.finalDateReview ? moment($scope.period.finalDateReview, 'YYYY-MM-DD').toDate() : '';

    $scope.minDate = $scope.period.finalDate;
	};

  $scope.cancel = function() {
		$uibModalInstance.dismiss();
	};

  $scope.confirm = function() {
    swal({
      title: 'Modificar período',
      text: '¿Está seguro que desea modificar el período actual? Si cambia la \'fecha hasta\' el sistema desplazará la vigencia de los períodos siguientes.',
      type: 'info',
      showCancelButton: true,
      confirmButtonClass: 'btn-info',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      closeOnConfirm: false,
      closeOnCancel: true,
    },
    function (isConfirm) {
      if (isConfirm) {
        PeriodService.ExtendPeriod($scope.period).then(function success(response) {
          swal('El período fue modificado correctamente.', '', 'success');
          $uibModalInstance.close();
        }, function error(response) {
          swal('El período no pudo ser modificado.', response.data.cause, 'error');
        });
      }
    });
    
	};

  $scope.init();
}])