'use strict';

angular.module('employees', ['paginationModule'])

  /**
   * ltl : Lead To Lead
   * ltc : Lead To Collaborator
   */
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider.state('search-employee', {
      url: '/search-employee',
      templateUrl: 'components/employees/employees.html',
      controller: 'EmployeesController'
    })
      .state('employee-info', {
        templateUrl: 'components/employees/employee-info.html',
        controller: 'EmployeeInfoController',
        resolve: {}
      })
      .state('employee-edit', {
        templateUrl: 'components/employees/employee-edit.html',
        controller: 'EmployeeEditController',
        resolve: {}
      })

  }])

  // .service('EmployeesService', ['$rootScope', '$http', 'restApiUrl', function($rootScope, $http, restApiUrl) {

  //   var service = {}; 

  //   service.GetEmployees = GetEmployees;

  //   function GetEmployees () {
  //     return $http.get(`${restApiUrl}/employees`);
  //   }
  //   return service;

  // }])

  .controller('EmployeesController', ['$scope', 'UsersService', 'restApiUrl', '$rootScope', '$translate', '$timeout', '$state', '$http', '$uibModal', function ($scope, UsersService, restApiUrl, $rootScope, $translate, $timeout, $state, $http, $uibModal) {

    // Variables para collapsar los filtros.
    $scope.collapsed = false;
    $scope.collapsedFilter = false;
    // Variables de los filtros.
    $scope.fullname = null;
    $scope.management = null;
    $scope.employeeId = null;

    $scope.showDischarged = false;

    $scope.collapseClick = function () {
      $scope.collapsed = !$scope.collapsed;
    };
    $scope.isCollapse = function () {
      return $scope.collapsed;
    };

    $scope.collapseFilterClick = function () {
      $scope.collapsedFilter = !($scope.collapsedFilter);
    }
    $scope.isFilterCollapsed = function () {
      return $scope.collapsedFilter;
    }

    $scope.makeFilter = function () {
      let filter = {};
      if ($scope.fullname !== null && $scope.fullname !== '') {
        filter['fullname'] = $scope.fullname;
      }
      if ($scope.management !== null && $scope.management !== '') {
        filter['management'] = $scope.management;
      }
      if ($scope.employeeId !== null && $scope.employeeId !== '') {
        filter['employeeId'] = $scope.employeeId;
      }

      var pageable = {};
      pageable['page'] = 1;
      pageable['limit'] = 10;
      filter.pageable = pageable;
      return filter;
    }


    $scope.getEmployees = function (currentPage) {
      let filter = $scope.makeFilter();
      filter.pageable.page = currentPage;
      UsersService.GetEmployees(filter).then(function (success) {
        $timeout(function () { $scope.$broadcast('loadPagination', success.data.paging); });
        if ($scope.showDischarged) {
          $scope.employees = success.data.result;
        }
        else {
          $scope.employees = success.data.result.filter(e => e.endDate == null);
        }
      }, function (error) {
        console.log('error fetching employees ', error);
      })

    }

    $scope.removeEmployee = function (employee) {
      swal({
        title: employee.fullname + ' será eliminado de la nómina',
        text: '¿Está seguro que desea continuar?',
        type: 'info',
        showCancelButton: true,
        confirmButtonClass: 'btn-info',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        closeOnConfirm: false,
        closeOnCancel: true,
      },
        function (isConfirm) {
          if (isConfirm) {
            UsersService.DeleteCollaborator(employee.employeeId).then(function (success) {
              swal('Eliminado', 'El colaborador fue eliminado.', 'success');
              $scope.init();
            },
              function (error) {
                let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
                swal('No ha sido posible realizar la operación.', errorText, 'error');
              })
          }
        });
    }

    $scope.reinstateEmployee = function (employee) {
      swal({
        title: employee.fullname + ' será reincorporado a la nómina',
        text: '¿Está seguro que desea continuar?',
        type: 'info',
        showCancelButton: true,
        confirmButtonClass: 'btn-info',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        closeOnConfirm: false,
        closeOnCancel: true,
      },
      function (isConfirm) {
        if (isConfirm) {
          UsersService.ReinstateCollaborator(employee.employeeId).then(function (success) {
            swal('Reincorporado', 'El colaborador fue reincorporado exitosamente.', 'success');
            $scope.init();
          },
            function (error) {
              let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
              swal('No ha sido posible realizar la operación.', errorText, 'error');
            })
        }
      });
    }

    $scope.init = function () {
      $scope.showDischarged = false;
      $scope.getEmployees(1);
    }

    $scope.cleanFilter = function () {
      $scope.fullname = null;
      $scope.management = null;
      $scope.employeeId = null;
    }

    $scope.goNewEmployee = function () {
      $state.go('new-employee');
    }

    $scope.moreInfo = function (employee) {
      var modal = $state.get('employee-info');
      modal.resolve.modalParams = function () {
        return {
          employee: employee,
        };
      };

      modal.windowClass = 'modal-zindex';
      modal.size = 'lg';
      var modalInstance = $uibModal.open(modal);

      modalInstance.result.then(function (val) {

        if (val === 'edit') {
          $scope.editEmployee(employee);
        }
      }, function () {
        //when error
      });
    }

    $scope.editEmployee = function (employee) {
      var modal = $state.get('employee-edit');
      modal.resolve.modalParams = function () {
        return {
          employee: employee,
          callbackInit: $scope.init
        };
      };

      modal.windowClass = 'modal-zindex';
      modal.size = 'lg';
      var modalInstance = $uibModal.open(modal);

      modalInstance.result.then(function (val) {
        $scope.init();
      }, function () {
        //when error
      });

    }
    $scope.init();

    $scope.$on('$viewContentLoaded', function () {
      $scope.getEmployees(1);
    });    

  }]);
