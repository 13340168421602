'use strict';

angular.module('login', ['translate', 'app.config', 'ui.router', 'ngResource'])

.config([ '$stateProvider', '$urlRouterProvider',
	function($stateProvider) {                
        $stateProvider.state('login', {
            url: '/login',
            views: {
                'loginView': {
                    templateUrl: 'components/login/login.html',
                    controller: 'LoginController'                
                }
            }
		});
}])

.controller('LoginController', [
	'$scope',
    '$rootScope',
    '$auth',
    '$localStorage',
    '$location',
    '$http',
    'jwtHelper',
    '$state',
    'OAUTH_AZURE_LOGIN_URL',
	function($scope, $rootScope, $auth, $localStorage, $location,$http,jwtHelper, $state,OAUTH_AZURE_LOGIN_URL) {

		$scope.authenticate = function(provider) {
            if(provider == 'google'){
                $auth.authenticate(provider).then(function (response) {
                    console.debug('logged in');
    
                    //Store loggedUser data
                    $localStorage.loggedUser = {
                        'username': $auth.getPayload().sub,
                        'name': $auth.getPayload().name,
                        'picture': $auth.getPayload().picture,
                        'email': $auth.getPayload().email,
                        'authorities': $auth.getPayload().authorities,
                        'loggedType': 'Google',
                        'token': 'Bearer ' + response.data.token
                    };
                    
                    //Store roles flag
                    $localStorage.loggedUser.isLeader = HasRole('HACIENDOBIND_LIDER');
                    $localStorage.loggedUser.isCollaborator = HasRole('HACIENDOBIND_COLABORADOR');
                    $localStorage.loggedUser.isAdmin =  HasRole('HACIENDOBIND_ADMIN');
               
                    $rootScope.$broadcast('logguedUser');
                    $location.path('/dashboard');
                }).catch(function (response) {
                    console.log('Error during authentication...');
                    $location.path('/login');
                });
            }else if(provider =='microsoft'){
                loginMicrosoft();           
            }
        };
        
        function loginMicrosoft(){
            const loginRequest = {
                scopes: ['openid', 'profile', 'user.read'], // Lista de permisos necesarios
            };
            $rootScope.msalInstance.loginPopup(loginRequest)
            .then(response => {
                callBackendEndpoint(response);            
            })
            .catch(error => {
                console.error('Error de autenticación:', error);
            });
        }

        function callBackendEndpoint(microsoftResponse) {
            $http({
                method: 'POST',
                url: OAUTH_AZURE_LOGIN_URL,
                headers: {
                    'LogginType': 'Microsoft',
                    'Authorization': 'Bearer ' + microsoftResponse.accessToken
                },
                data: { accessToken: microsoftResponse.accessToken }
            }).then(function(response) {
                    var decodedToken = jwtHelper.decodeToken(response.data.token);
                    $localStorage.loggedUser = {
                        'username': microsoftResponse.idTokenClaims.preferred_username,
                        'name': decodedToken.name,
                        'picture': 'data:image/png;base64,' + response.data.picture,
                        'email': microsoftResponse.idTokenClaims.preferred_username,
                        'authorities': decodedToken.authorities,
                        'loggedType': 'Microsoft',
                        'token': 'Bearer ' + microsoftResponse.accessToken
                    };

                    //Store roles flag
                    $localStorage.loggedUser.isLeader = HasRole('HACIENDOBIND_LIDER');
                    $localStorage.loggedUser.isCollaborator = HasRole('HACIENDOBIND_COLABORADOR');
                    $localStorage.loggedUser.isAdmin =  HasRole('HACIENDOBIND_ADMIN');

                    $rootScope.$broadcast('logguedUser');
                    $location.path('/dashboard');
                })
                .catch(function(error) {
                    console.error('Error al llamar al endpoint:', error);
                });
            }

		$scope.isAuthenticated = function() {
			return $auth.isAuthenticated() || $localStorage.loggedUser &&  $localStorage.loggedUser.loggedType === 'Microsoft' &&  $localStorage.loggedUser.token;
        };

        function HasRole(role){
            var authorities = $localStorage.loggedUser.authorities;
            for (let index = 0; index < authorities.length; index++) {
                const element = authorities[index];
                if(element === role){
                    return true;
                }
            }
            return false;
        }        
    }]);
    
    