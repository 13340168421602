'use strict';

angular.module('performance-evaluation')

	.controller('PerformanceEvaluationLtlFifthStepController', ['$scope', '$stateParams', '$state', '$rootScope', '$translate', '$uibModal', 'PerformanceEvaluationService', '$localStorage', function ($scope, $stateParams, $state, $rootScope, $translate, $uibModal, PerformanceEvaluationService, $localStorage) {
		$scope.loggedUsername = $scope.loggedUser.email.toLowerCase();
		$scope.evaluation = null;
		$scope.disabled = $stateParams.disabled;
		$scope.adminWatching = $stateParams.adminWatching;
		$scope.canSave = false;

		$scope.init = function () {
			$scope.incompleteFields = [];
			$scope.requiredFields = [
			];

			if ($stateParams.evaluationId == undefined) {
				$scope.evalId = $localStorage.actualPerformanceId;
			}
			else {
				$scope.evalId = $stateParams.evaluationId;
			}
			PerformanceEvaluationService.GetPerformanceEvaluation($scope.evalId).then(function success(response) {
				$scope.evaluation = response.data;
				$scope.useObjectives = response.data.objectivesDto;
				$scope.useObjectives2 = response.data.objectivesBehaviorDto;

				$scope.evaluation.valuesCalification.sort((x, y) => $scope.compareValueName(x.valueName, y.valueName));
				$scope.loggedUsername = $scope.loggedUser.email.toLowerCase();
				// lead-lead evaluation, we check if the logged user is the evaluator or the evaluatee
				if ($scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase()) {
					$scope.loggedUserRoleInEval = 'EVALUATOR';
					$scope.currentStep = $scope.evaluation.currentStepEvaluator
				} else {
					if ($scope.loggedUsername === $scope.evaluation.evaluatedDto.email.toLowerCase()) {
						$scope.loggedUserRoleInEval = 'EVALUATEE';
						$scope.currentStep = $scope.evaluation.currentStepEvaluatee
						$scope.canSave = true;
					}
				}
				$scope.getEmptyFields();

				formatDateToUse($scope.useObjectives);
				formatDateToUse($scope.useObjectives2);

				PerformanceEvaluationService.GetEvaluationStrategicDriverValues().then(function success(response) {
					$scope.evaluationStrategicDriverValues = response.data;
					handleStrategicDriverValues();
				}, function error(response) {
					console.error('Error al recuperar los valores del Enum EvaluationStrategicDriver', response);
				});
			}, function error(_error) {
				console.log('error fetching evaluation', id)
			})

		};

		$scope.continue_evaluation = function (evaluation) {

			if ($scope.canSave){
				$scope.deleteEmptyOkr($scope.useObjectives);
				$scope.formatDate($scope.useObjectives2);
				$scope.formatDate($scope.useObjectives);

				$scope.evaluation.objectivesDto = $scope.useObjectives;
				$scope.evaluation.currentStepEvaluatee = 5;

				PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
					function success(_response) {
						$state.go('ltl-fifth-step-summary');
					}, function error(error) {
						console.log(error)
						let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
						swal('Error al guardar la evaluación.', errorText, 'error');
						console.log('failure in continue evaluation-1');
					}
				);
			}
		};

		$scope.save = function (evaluation) {

			if ($scope.canSave){
				$scope.deleteEmptyOkr($scope.useObjectives);
				$scope.formatDate($scope.useObjectives2);
				$scope.formatDate($scope.useObjectives);

				$scope.evaluation.objectivesDto = $scope.useObjectives;

				PerformanceEvaluationService.SaveEvaluationProgress(evaluation).then(
					function success(_response) {
						swal('Guardada', 'Evaluación guardada con éxito', 'success');
					}, function error(error) {
						let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
						swal('Error al guardar la evaluación.', errorText, 'error');
						console.log('failure in continue evaluation-1');
					}
				);
			}
		};

		$scope.confirm_evaluation = function (evaluation) {
			let modalTitle = '';
			let modalDescription = '';
			let doComplete = undefined;

			if ($scope.evaluation.evaluatedDto.email.toLowerCase() === $scope.loggedUsername) {
				modalTitle = 'Planificación completa';
				modalDescription = `Gracias por completar la planificación. Ahora ${$scope.evaluation.evaluatorDto.fullname} debe revisar la planificación.`;
				doComplete = false;
			}
			if ($scope.evaluation.evaluatorDto.email.toLowerCase() === $scope.loggedUsername && $scope.evaluation.state === 'IN_PROGRESS_COLLAB') {
				modalTitle = 'Planificación completa';
				modalDescription = `Espere a que el Colaborador ${$scope.evaluation.evaluatedDto.fullname} termine de completar la planificación para finalizarla.`;
				doComplete = false;
			}
			if ($scope.evaluation.evaluatorDto.email.toLowerCase() === $scope.loggedUsername && $scope.evaluation.state === 'IN_PROGRESS') {	
					modalTitle = 'Planificación en progreso';
					modalDescription = `Espere a que el Colaborador ${$scope.evaluation.evaluatedDto.fullname} termine de completar la planificación para finalizarla.`;
					doComplete = false;
				}
			if ($scope.evaluation.evaluatorDto.email.toLowerCase() === $scope.loggedUsername && $scope.evaluation.state === 'COMPLETED') {
				modalTitle = 'Finalizar planificación';
				modalDescription = 'Planificación completada por ambas partes. ¿Desea finalizarla?';
				doComplete = true;
			}
			if ($scope.evaluation.evaluatorDto.email.toLowerCase() === $scope.loggedUsername && $scope.evaluation.state === 'IN_MID_CYCLE_REVIEW') {
				modalTitle = 'Finalizar planificación';
				modalDescription = 'Planificación completada por ambas partes. ¿Desea finalizarla?';
				doComplete = true;
			}

			swal({
				title: modalTitle,
				text: modalDescription,
				type: 'info',
				showCancelButton: true,
				confirmButtonClass: 'btn-info',
				confirmButtonText: 'Confirmar',
				cancelButtonText: 'Cancelar',
				closeOnConfirm: true,
				closeOnCancel: true,
			},
				function (isConfirm) {
					if (isConfirm) {
						if ($scope.evaluation.evaluatedDto.email.toLowerCase() === $scope.loggedUsername) {
							$scope.formatDate($scope.useObjectives);
							$scope.formatDate($scope.useObjectives2);
							$scope.evaluation.currentStepEvaluatee = 1;
							PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
								(success) => {
									swal('¡Planificación notificada con éxito!', '', 'success');
									$scope.goToConditional();
								},
								(error) => {
									if(error.data.cause !== undefined){
										let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
										swal('Error al notificar la planificación.', errorText, 'error');
										$scope.validationErrors = error.data.validationErrors;
									}else{
										swal('Error al notificar la planificación', 'Error desconocido', 'error')
									}
									console.log('failure in continue evaluation-1', error);
								}
							);
						}
						if (doComplete) {
							$scope.formatDate($scope.useObjectives);
							$scope.formatDate($scope.useObjectives2);
							PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
								(success) => {
									swal('¡Planificación completada con éxito!', '', 'success');
									$scope.goToConditional();
								},
								(error) => {
									if(error.data.cause !== undefined){
										let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
										swal('Error al guardar la planificación.', errorText, 'error');
										$scope.validationErrors = error.data.validationErrors;
									}else{
										swal('Error al guardar la planificación', 'Error desconocido', 'error')
									}
									console.log('failure in continue evaluation-1', error);
								})
						}
					}
				});
		};

		$scope.review_objectives = function (evaluation){
			let modalTitle = '';
			let modalDescription = '';
			let doReview = undefined;

			console.log(evaluation)

			if ($scope.evaluation.evaluatorDto.email.toLowerCase() === $scope.loggedUsername && $scope.currentStepEvaluatee === 5 && $scope.evaluation.state === 'IN_MID_CYCLE_REVIEW') {
				modalTitle = 'Revisar planificación';
				modalDescription = `Planificación completada por ${$scope.evaluation.evaluatedDto.fullname}. ¿Desea revisarla?`;
				doReview = true;
			}
			else {	
				modalTitle = 'Planificación en progreso';
				modalDescription = `Espere a que el Colaborador ${$scope.evaluation.evaluatedDto.fullname} termine de completar la revisión de mitad de ciclo para revisarla.`;
				doReview = false;
			}

			swal({
				title: modalTitle,
				text: modalDescription,
				type: 'info',
				showCancelButton: true,
				confirmButtonClass: 'btn-info',
				confirmButtonText: 'Confirmar',
				cancelButtonText: 'Cancelar',
				closeOnConfirm: true,
				closeOnCancel: true,
			},
				function (isConfirm) {
					if (isConfirm) {
						if (doReview) {
							$scope.formatDate($scope.useObjectives);
							$scope.formatDate($scope.useObjectives2);
							$scope.evaluation.state = 'COMPLETED';

							PerformanceEvaluationService.PostPerformanceEvaluation($scope.evaluation).then(
								(success) => {
									console.log(JSON.stringify('State sent: '+$scope.evaluation.state));
									swal('¡Planificación a revisión!', '', 'success');
									$scope.goToConditional();
								},
								(error) => {
									if(error.data.cause !== undefined){
										let errorText = $translate.instant('load.ERRORS.' + error.data.cause);
										swal('Error al guardar la planificación.', errorText, 'error');
										$scope.validationErrors = error.data.validationErrors;
									}else{
										swal('Error al guardar la planificación', 'Error desconocido', 'error')
									}
									console.log('failure in continue evaluation-1', error);
								})
						}
						else {
							$state.go('dashboard')
						}
					}
				});
		}

		function handleStrategicDriverValues(){
			$scope.getStrategicDriver = function (objective){
				let value = $scope.evaluationStrategicDriverValues.find(element => element.name === objective.strategicDriver).value;
				return value;
			}
		}

		$scope.compareValueName = function (a, b) {
			if (a.valueName == b.valueName) {
				return $scope.compareAspectName(a, b);
			}
			if (a.valueName < b.valueName) {
				return -1;
			}
			if (a.valueName > b.valueName) {
				return 1;
			}
		}

		$scope.compareAspectName = function (a, b) {
			if (a.aspectName < b.aspectName) {
				return -1;
			}
			if (a.aspectName > b.aspectName) {
				return 1;
			}
			return 0;
		}

		$scope.isEvaluator = function () {
			return $scope.loggedUsername === $scope.evaluation.evaluatorDto.email.toLowerCase();
		}

		$scope.getEmptyFields = function () {		
			$scope.requiredFields.forEach(function (x) {
				 if (!$scope.evaluation[x.field]){
					$scope.incompleteFields.push(x);
				 }
			});
		}

		//ver si cambiar
		$scope.collaborator_can_see = function (evaluationState) {
			var validAdmin = $localStorage.loggedUser.isAdmin;
			var validLider = $localStorage.loggedUser.isLeader && $scope.loggedUserRoleInEval === 'EVALUATOR';
			var validColab = $localStorage.loggedUser.isCollaborator && $scope.loggedUserRoleInEval === 'EVALUATEE' && ['IN_PROGRESS_COLLAB', 'FINISHED', 'COMPLETED'].includes(evaluationState);
			return (validAdmin || validLider || validColab);
		}

		$scope.goToConditional = function () {
			let isEvaluator = $scope.isEvaluator();
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else {
				$state.go('my-evaluation');
			}
		}

		$scope.back = function (id, _evaluation) {
			let isEvaluator = $scope.isEvaluator();
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else if (!(isEvaluator)) {
				$state.go('my-evaluation');
			}
		}

		$scope.close = function (_id, _evaluation) {
			let isEvaluator = $scope.isEvaluator();
			console.log('asdf', $scope.adminWatching);
			if ($scope.adminWatching) {
				$state.go('search-evaluation');
				return;
			}
			if (isEvaluator) {
				$state.go('performance-evaluation');
			} else if (!(isEvaluator)) {
				$state.go('my-evaluation');
			}
		}

		$scope.formatDate = function (useObjectives){
			for (let i=0; i < useObjectives.length; i++){
				for (let j=0; j< useObjectives[i].keyResults.length; j++){
					let date = useObjectives[i].keyResults[j].timeLimit;
					date = moment(date);
					useObjectives[i].keyResults[j].timeLimit = date.format('YYYY-MM-DD');
				}
			}
		}

		function formatDateToUse(objectives){
			for (let i=0; i < objectives.length; i++){
				for (let j=0; j< objectives[i].keyResults.length; j++){
					let date = objectives[i].keyResults[j].timeLimit;
					objectives[i].keyResults[j].timeLimit = new Date(date);
				}
			}
		}

		$scope.deleteEmptyOkr = function (objectives) {
			for (let i = objectives.length - 1; i >= 0; i--) {
				var objective = objectives[i];
				if (objective.description === '' && objective.complianceDegree === 0 && objective.keyResults.length === 0) {
					objectives.splice(i, 1);
				}
			}
		}

		$scope.isCurrentDateInRange = function(initialDateReview, finalDateReview) {
			let currentDate = new Date();
			let startDate = new Date(initialDateReview);
			let endDate = new Date(finalDateReview);
	
			return currentDate >= startDate && currentDate <= endDate;
		};

		$scope.init();

	}]);
